const cursor = function () {
    var follower, init, mouseX, mouseY, positionElement, timer;

    follower = document.createElement('div');
    follower.className += 'cursor';
    document.body.appendChild(follower);
    
    var DOM = ['.navbar-toggler', 'button', 'a' ];

    DOM.forEach(function(item, idx){
        if(document.querySelector(item)){
             var a = document.querySelectorAll(item);
            a.forEach(function(e, i){
                e.classList.add('__cursor');
            })
        }
    });
    
    function mouseEnter(){ 
        follower.classList.add('__hover'); 
    };
    
    function mouseLeave(){ 
        follower.classList.remove('__hover');
    }; 
    
    var cursor = document.querySelectorAll('.__cursor');
    for (var i = 0; i < cursor.length; i++) {
        cursor[i].addEventListener('mouseenter', mouseEnter, false);
        cursor[i].addEventListener('mouseleave', mouseLeave, false);
    }

    
    
    mouseX = (event) => {
        return event.clientX;
    };

    mouseY = (event) => {
        return event.clientY;
    };

    positionElement = (event) => {
        var mouse;
        mouse = {
            x: mouseX(event),
            y: mouseY(event)
        };
        follower.style.top = mouse.y + 'px';
        return follower.style.left = mouse.x + 'px';
    };

    timer = false;

    window.onmousemove = init = (event) => {
        var _event;
        _event = event;
        return timer = setTimeout(() => {
            return positionElement(_event);
        }, 1);
    };

}

export default cursor;