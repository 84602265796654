import React from "react"
import { Link } from "gatsby"

import logo from "../images/logo.png"
import "../styles/components/footer.scss"

const Footer = props => {
  const social = props.meta.social
  return (
    <footer>
      <div className="container-fluid">
        <div className="inner text-center">
          <div className="top">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="bottom">
            <p className="text-center">
              Copyright © {new Date().getFullYear()}{" "}
              {props.meta.site_meta_keyword
                ? props.meta.site_meta_keyword
                : "Rich Bulls"}
            </p>
            <ol className="footer-social">
              {social && social.facebook_link ? (
                <li>
                  <a href={social.facebook_link} target="_blank">
                    <i className="icon icon-graphics-icon-fb"></i>
                  </a>
                </li>
              ) : null}
              {social && social.instagram_link ? (
                <li>
                  <a href={social.instagram_link} target="_blank">
                    <i className="icon icon-graphics-icon-insta"></i>
                  </a>
                </li>
              ) : null}
              {social && social.twitter_link ? (
                <li>
                  <a href={social.twitter_link} target="_blank">
                    <i className="icon icon-graphics-icon-twtr"></i>
                  </a>
                </li>
              ) : null}
              {social && social.youtube_link ? (
                <li>
                  <a href={social.youtube_link} target="_blank">
                    <i className="icon icon-graphics-icon-youtube"></i>
                  </a>
                </li>
              ) : null}
              {social.whatsapp_link ? (
                <li>
                  <a href={social.whatsapp_link} target="_blank">
                    <i className="icon icon-social-whatsapp icon-2-social-whatsapp"></i>
                  </a>
                </li>
              ) : null}
            </ol>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
