/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import { Location } from "@reach/router"
import Helmet from "react-helmet"
import axios from "axios"
import { ReactReduxContext } from "react-redux"
import { detect } from "detect-browser"

import { CSSTransition } from "react-transition-group"
import { API_EN } from "../utils/config"

import Header from "./header"
import Footer from "./footer"

import Loader from "../components/loader"
import cursor from "../scripts/cursor"

import "../styles/style.scss"
const browser = detect()

//import "./layout.css"
/*
const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const bodyClass = location.pathname === "/" ? "home-page" : "inner-page";
  //document.body.classList.add(bodyClass);
  return (
    <>
      
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="section-main">{children}</main>
      <Footer />
    </>
  )
}
*/

class Layout extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = { isLoading: true, data: null }
    var pathname = props.location.pathname
    pathname = pathname.replace(props.meta.pathPrefix, "")
    pathname = pathname === "/" ? "/" : pathname.replace(/\//g, "")
    this.__path = pathname
  }

  async componentDidMount() {
    this._isMounted = true
    if (this._isMounted) {
      setTimeout(() => {
        document.body.classList.add("__loaded")
        //this.setState({isLoading: false});

        if (!document.documentElement.classList.contains("touch")) {
          var cur = document.querySelectorAll(".cursor")
          for (var i = 0; i < cur.length; i++) {
            cur[i].remove()
          }

          cursor()
        }
      }, 1000)

      window.addEventListener("scroll", this.handleScroll)

      const get = endpoint => axios.get(`${API_EN}/${endpoint}`)
      const { data: item } = await get(`settings`)
      if (this._isMounted) {
        this.setState({
          data: item.data,
          isLoading: false,
        })
      }
    }

    document.body.classList.add(browser.name)
    if ("ontouchstart" in document.documentElement)
      document.documentElement.className += " touch"
  }

  componentWillMount() {
    //window.test();
    this._isMounted = false
  }

  handleScroll(e) {
    if (this.scrollY > 0 || this.pageYOffset > 0) {
      document.body.classList.add("__scrolled")
    } else {
      document.body.classList.remove("__scrolled")
    }
  }
  render() {
    const loader = this.state.isLoading ? <Loader /> : ""
    const bodyClass =
      this.__path === "/" ? "home-page" : `inner-page __${this.__path}`

    const LayoutView = (
      <div className={`${bodyClass}`}>
        <Header
          path={this.__path}
          scrollInit={
            this.__path === "/" && this.props.location.hash ? true : false
          }
          hash={this.props.location.hash}
          meta={this.state.data}
        />
        <main className="section-main">{this.props.children}</main>
        <Footer meta={this.state.data} />
      </div>
    )

    return (
      <>
        <Helmet></Helmet>
        {loader}
        <CSSTransition
          in={!this.state.isLoading && this._isMounted}
          timeout={0}
          classNames="__page"
          unmountOnExit
        >
          {LayoutView}
        </CSSTransition>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

// const useSiteMetadata = () => {
//   const { site } = useStaticQuery(
//     graphql`
//       query {
//         site {
//           siteMetadata {
//             title
//           }
//         }
//       }
//     `
//   )
//   return site.siteMetadata
// }

// export default props => (
//   <Location>
//     {locationProps => <Layout {...locationProps} {...props} />}
//   </Location>
// );

//export default Layout

// console.log(connect)

// const mapStateToProps = (state) => {
//   return { state }
// }

// const mapDispatchToProps = dispatch => {
//   return {}
// }

export default props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          pathPrefix
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <ReactReduxContext.Consumer>
      {({ store }) => {
        return (
          <Location>
            {locationProps => (
              <Layout {...locationProps} {...props} store={store} meta={site} />
            )}
          </Location>
        )
      }}
    </ReactReduxContext.Consumer>
  )
}
