import { withPrefix, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import axios from "axios"
import { API_EN } from "../utils/config"
import { navigate } from "@reach/router"
import $ from "jquery"
import logo from "../images/logo.png"

import "../styles/components/header.scss"

class Header extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.toggleNav = this.toggleNav.bind(this)
    this.navClose = this.navClose.bind(this)
    this.navScroll = this.navScroll.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.toTop = this.toTop.bind(this)

    this._space = window.innerWidth < 992 ? 68 : 90

    this._scrollOffset = {
      contact: window.innerWidth < 992 ? 100 : 140,
      messages: window.innerWidth < 992 ? 68 : 40,
      banner: 0,
    }

    this.state = {
      navList: null,
    }
  }
  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener("scroll", this.handleScroll)
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true)
    this.fetchData()

    if (this.props.hash == "#banner") this._space = this._scrollOffset.banner
    if (this.props.hash == "#contact") this._space = this._scrollOffset.contact
    if (this.props.hash == "#messages")
      this._space = this._scrollOffset.messages

    if (this.props.scrollInit && $(this.props.hash).length > 0) {
      setTimeout(() => {
        $("html, body")
          .stop()
          .animate(
            { scrollTop: $(this.props.hash).offset().top - this._space },
            1000
          )
      }, 200)
    }

    // $('.navbar-right .nav-item').removeClass('active');
    // $('.navbar-right .nav-item[href="/'+ this.props.hash +'"]').addClass('active');
  }

  async fetchData() {
    this._isMounted = true
    const get = endpoint => axios.get(`${API_EN}/${endpoint}`)
    const { data: item } = await get(`pages`)
    if (this._isMounted) {
      this.setState({
        navList: item.data,
      })
    }
  }
  toggleNav = e => {
    e.preventDefault()
    document.querySelector(".navbar-right").classList.toggle("__open")
  }

  navClose = e => {
    e.preventDefault()
    document.querySelector(".navbar-right").classList.remove("__open")
  }

  navScroll = e => {
    e.preventDefault()
    var hashLoc = e.target.hash

    $(".navbar-right .nav-item").removeClass("active")
    $(e.target).addClass("active")

    if (hashLoc == "#banner") this._space = this._scrollOffset.banner
    if (hashLoc == "#contact") this._space = this._scrollOffset.contact
    if (hashLoc == "#messages") this._space = this._scrollOffset.messages

    document.querySelector(".navbar-right").classList.remove("__open")
    navigate(withPrefix(`/${hashLoc}`))

    if ($(hashLoc).length) {
      $("html, body").animate(
        {
          scrollTop: $(hashLoc).offset().top - this._space,
        },
        700
      )
    }
  }

  toTop = e => {
    if (this.props.path === "/") {
      e.preventDefault()
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        700
      )
    }
  }

  handleScroll = e => {
    if (this.props.path === "/") {
      var scrollPos = $(document).scrollTop()
      $(".navbar-right a").each(function() {
        var currLink = $(this)
        var refElement = $(currLink.attr("data-href"))
        if ($(refElement).length) {
          if (
            refElement.position().top <= scrollPos &&
            refElement.position().top + refElement.height() > scrollPos
          ) {
            $(".navbar-right a").removeClass("active")
            currLink.addClass("active")
          } else {
            currLink.removeClass("active")
          }
        }
      })
    }
  }
  render() {
    const menuList = this.state.navList

    return (
      <header>
        <div className="container">
          <div className="header-inner">
            <div className="header-left">
              <div className="navbar-left">
                <nav>
                  {/* <Link to="/services">Services</Link> */}
                  <Link
                    to="/#services"
                    className="nav-item"
                    onClick={this.navScroll}
                  >
                    Services
                  </Link>
                  <Link
                    to="/#contact-us"
                    className="nav-item"
                    onClick={this.navScroll}
                  >
                    Contact
                  </Link>
                </nav>
                <span className="line">
                  <em></em>
                </span>
              </div>
            </div>
            <div className="header-center">
              <Link to="/" onClick={this.toTop}>
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <div className="header-right">
              <span className="line">
                <em></em>
              </span>
              <div className="navbar-toggler" onClick={this.toggleNav}>
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              </div>
              <div className="navbar-right">
                <nav>
                  {menuList &&
                    menuList.map((menu, index) => {
                      if (menu.is_main_navigation) {
                        return (
                          <li key={menu.page_slug}>
                            <a
                              data-href={`#${menu.page_slug}`}
                              href={withPrefix(`/#${menu.page_slug}`)}
                              className={
                                this.props.hash === "#" + menu.page_slug ||
                                this.props.path === menu.page_slug
                                  ? "nav-item active"
                                  : "nav-item"
                              }
                              key={menu.title}
                              onClick={this.navScroll}
                            >
                              {menu.page_name}
                            </a>
                          </li>
                        )
                      }
                    })}
                  <span className="nav-close" onClick={this.navClose}>
                    <i className="icon icon-icons-close"></i>
                  </span>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
