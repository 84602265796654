import React from "react"
import logo from "../images/logo.png"

import style from "../styles/components/common.scss";

const Loader = (props) => {
    return(
        <div className="loader">
            <div className="loader-block">
                <div className="loader-img">
                    <img src={logo} />
                </div>
                <div className="linePreloader"></div>
            </div>
        </div>
    );
}
export default Loader;