//export const BASE_URL = 'http://localhost:8000';
// export const API_CONTACT = 'https://cors-anywhere.herokuapp.com/http://ec2-52-32-188-141.us-west-2.compute.amazonaws.com/richbull_cms/beta/api/contact';
// export const API_EN = 'https://cors-anywhere.herokuapp.com/http://ec2-52-32-188-141.us-west-2.compute.amazonaws.com/richbull_cms/beta/api/data/en';

//export const API_CONTACT = "https://devpgs.com/richbull_cms/beta/api/contact"
//export const API_EN = "https://devpgs.com/richbull_cms/beta/api/data/en"

export const API_CONTACT = "https://www.richbulls.com/RCB-CNT/api/contact"
export const API_EN = "https://www.richbulls.com/RCB-CNT/api/data/en"

//var full_api_url = window.location.protocol + "//" + window.location.hostname
//export const API_CONTACT = full_api_url + "/RCB-CNT/api/contact"
//export const API_EN = full_api_url + "/RCB-CNT/api/data/en"
